body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  opacity: 0.5;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}
@font-face {
  font-family: 'Inter-SemiBold';
  src: local('Inter-SemiBold'), url(../../static/media/Inter-SemiBold.ec60b23f.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter-Regular';
  src: local('Inter-Regular'), url(../../static/media/Inter-Regular.515cae74.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter-Light';
  src: local('Inter-Light'), url(../../static/media/Inter-Light.6ffbefc6.ttf) format('truetype');
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.container{
  min-width: 300px;
  max-width: 1300px;
  background-color: #E5E5E5;
}

@media(min-width: 1001px){
  .container {
    width: 40% !important;
    margin: auto !important;
  }
}

@media(max-width: 1000px){
  .title {
    font-size: 28px !important;
  }

  .btn {
    padding: 10px !important;
    font-size: 13px !important;
    margin-right: 10% !important;
    margin-left: 10% !important;
    width: 80% !important;
  }

  .footer {
    margin-right: 10% !important;
    margin-left: 10% !important;
    width: 80% !important;
  }

  #loading-gif {
    width: 5% !important;
    margin-top: 25% !important;
  }

  .container {
    width: 60% !important;
    margin: auto !important;
  }
}

@media(max-width: 600px){
  .title {
    font-size: 29px !important;
  }

  #logo{
    margin-bottom: 40px !important;
    margin-top: 25px !important;
  }

  #figure {
    margin-right: 10% !important;
    margin-left: 10% !important;
    width: 80% !important;
  }

  #loading-gif {
    width: 10% !important;
    margin-top: 50% !important;
  }

  .container {
    width: 100% !important;
    margin: auto !important;
  }
}

.modal-wrapper {
  position: fixed;
  background: white;
  border: 1px solid #d0cccc;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.17);
  margin-top: 190px;
  transition: all 0.8s;
  left: 40%;
  right: 40%;
  width: 20%;
  border-radius: 12px;
}

.modal-header {
  height: 40px;
  line-height: 40px;
  padding: 5px 14.5px 5px 20px;
  text-align: right;
  border-bottom: unset !important;
}

.modal-body {
  padding: 5px 15px 10px 15px;
  text-align: center;
  height: 200px;
}

.modal-footer {
  height: 85px;
  padding: 15px 15px 20px 15px;
  border-top: unset !important;
}

.modal-figure {
  width: 55%;
}

.close-modal-btn {
  color: black;
  cursor: pointer;
  float: right;
  font-size: 20px;
  margin: 0;
}

.close-modal-btn:hover {
  color: grey;
}

.btn-cancel {
  background-color: #b71c1c;
  float: left;
}

.btn-continue {
  background-color: #1b5e20;
  float: right;
}

.back-shed {
  background-color: rgba(0, 0, 0, 0.3);
  height: 100%;
  position: fixed;
  transition: all 0.8s;
  width: 100%;
}

.open-modal-btn {
  margin: 15px;
  padding: 10px;
}

@media (min-width: 2001px) {
  .modal-wrapper {
    right: 42%;
    left: 42%;
    width: 16%;
  }
  .modal-body {
    height: 10.5vw;
  }
  .modal-text {
    font-size: 0.7vw !important;
  }
  .modal-button {
    font-size: 0.6vw !important;
  }
  .modal-close {
    width: 36% !important;
  }
}

@media (max-width: 2000px) {
  .modal-body {
    height: 12.5vw;
  }
}

@media (max-width: 1500px) {
  .modal-wrapper {
    right: 37%;
    left: 37%;
    width: 26%;
  }
  .modal-body {
    height: 17vw;
  }
}

@media (max-width: 1000px) {
  .modal-wrapper {
    left: 32%;
    right: 32%;
    width: 36%;
  }
  .modal-body {
    height: 24vw;
  }
}

@media (max-width: 600px) {
  .modal-wrapper {
    left: 15%;
    right: 15%;
    width: 70%;
    margin-top: 180px;
  }
  .modal-body {
    height: 46vw;
  }
}

@media (max-width: 300px) {
  .modal-wrapper {
    left: 10%;
    right: 10%;
    width: 80%;
  }
  .modal-body {
    height: 155px;
  }
  .modal-close {
    width: 30% !important;
  }
}

