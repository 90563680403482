.modal-wrapper {
  position: fixed;
  background: white;
  border: 1px solid #d0cccc;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.17);
  margin-top: 190px;
  transition: all 0.8s;
  left: 40%;
  right: 40%;
  width: 20%;
  border-radius: 12px;
}

.modal-header {
  height: 40px;
  line-height: 40px;
  padding: 5px 14.5px 5px 20px;
  text-align: right;
  border-bottom: unset !important;
}

.modal-body {
  padding: 5px 15px 10px 15px;
  text-align: center;
  height: 200px;
}

.modal-footer {
  height: 85px;
  padding: 15px 15px 20px 15px;
  border-top: unset !important;
}

.modal-figure {
  width: 55%;
}

.close-modal-btn {
  color: black;
  cursor: pointer;
  float: right;
  font-size: 20px;
  margin: 0;
}

.close-modal-btn:hover {
  color: grey;
}

.btn-cancel {
  background-color: #b71c1c;
  float: left;
}

.btn-continue {
  background-color: #1b5e20;
  float: right;
}

.back-shed {
  background-color: rgba(0, 0, 0, 0.3);
  height: 100%;
  position: fixed;
  transition: all 0.8s;
  width: 100%;
}

.open-modal-btn {
  margin: 15px;
  padding: 10px;
}

@media (min-width: 2001px) {
  .modal-wrapper {
    right: 42%;
    left: 42%;
    width: 16%;
  }
  .modal-body {
    height: 10.5vw;
  }
  .modal-text {
    font-size: 0.7vw !important;
  }
  .modal-button {
    font-size: 0.6vw !important;
  }
  .modal-close {
    width: 36% !important;
  }
}

@media (max-width: 2000px) {
  .modal-body {
    height: 12.5vw;
  }
}

@media (max-width: 1500px) {
  .modal-wrapper {
    right: 37%;
    left: 37%;
    width: 26%;
  }
  .modal-body {
    height: 17vw;
  }
}

@media (max-width: 1000px) {
  .modal-wrapper {
    left: 32%;
    right: 32%;
    width: 36%;
  }
  .modal-body {
    height: 24vw;
  }
}

@media (max-width: 600px) {
  .modal-wrapper {
    left: 15%;
    right: 15%;
    width: 70%;
    margin-top: 180px;
  }
  .modal-body {
    height: 46vw;
  }
}

@media (max-width: 300px) {
  .modal-wrapper {
    left: 10%;
    right: 10%;
    width: 80%;
  }
  .modal-body {
    height: 155px;
  }
  .modal-close {
    width: 30% !important;
  }
}
